import axios from './request'
export function setPriceMaker(body){
  return axios({
      url: "/autoCollection/SetPriceMaker",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      params: body
  })
}
export function priceMakerPage(body){
  return axios({
      url: "/autoCollection/MpUnMakedList",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,   
      params: body
  })
}
export function mpMakedList(body){
  return axios({
      url: "/autoCollection/MpMakedList",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,   
      params: body
  })
}
export function setVisitTime(body){
  return axios({
      url:"/ScrapOrder/setVisitTime",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      params: body
  });
}
export function commission(body){
  return axios({
      url: "/provider/Commission",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      data: body
  })
}
export function saveDoorPhoto(body){
  return axios({
      url: "/provider/SaveDoorPhoto",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      params: body
  })
}
export function TrailerPhoto(body){
  return axios({
      url:"/PriceMaker/TrailerPhoto",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      params: body
  });
}
export function getCQeustion(body){
  return axios({
      url: "/scrapOrder/GetCQeustion",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,   
      params: body
  })
}
export function getMakerPrice(body){
  return axios({
      url: "/autoCollection/GetMakerPrice",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      data: body
  })
}
export function confirmOrder(body){
  return axios({
      url: "/PriceMaker/ConfirmOrder",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      data: body
  })
}
export function SendScrapSMS(body){
  return axios({
      url:"/SMS/SendScrapSMS",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,            
      params: body
  });
}
export function setWeight(body){
  return axios({
      url:"/autoCollection/SetWeighing",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,            
      params: body
  })}
