<template>
    <div id="pricing_app_div">
        <div style="text-align:center;font-size: 16px;font-weight: 500;width:100%;height: 46px;top:0px; line-height:46px;z-index: 1;position:fixed;background:#fff;">
            {{titleArr[active].title}}
        </div>
        <div style="margin-top:47px;">
            <div v-if="active==0">
                <my-pricing></my-pricing>
            </div>
             <div v-if="active==1">
                <pricingList></pricingList>
            </div>
            <div v-if="active==2">
                <pricingUserInfo></pricingUserInfo>
            </div>
        </div>
        <div class="tabbar_bottom_div">
            <van-tabbar v-model="active"  @change="onChange">
                <van-tabbar-item v-for="(item,index) in titleArr"  :key="index" :icon="active==index?item.active_icon:item.icon">{{item.title}}</van-tabbar-item>
            </van-tabbar>
        </div>
    </div>
</template>
<script>
import {pricingActive} from '../../store/pricing'
import pricingList from '../pricingNewList/pricingNewList.vue'
import myPricing from '../pricingUserList/pricingUserList.vue'
import pricingUserInfo from '../pricingUserInfo/pricingUserInfo.vue'
import {validToken} from '../../api/login'
import { Tabbar,TabbarItem } from 'vant';


export default({
    name:'pricingIndex',
    components: {
        pricingList,
        myPricing,
        pricingUserInfo,
        [Tabbar.name]: Tabbar,
        [TabbarItem.name]: TabbarItem,
    },
    data() {
        return {
            agentUser:{},
            titleArr:[
                {title:'正在处理',active_icon:'add',icon:'add-o'},
                {title:'获取线索',active_icon:'wap-home',icon:'wap-home-o'},
                {title:'我的信息',active_icon:'manager',icon:'manager-o'}],
            active: 0,
        }
    },
    methods:{
        onChange(index){
            this.active = index;
            pricingActive.set(index)
        }
    },
    mounted(){
        this.active = parseInt(pricingActive.get()||'0');
    },
    created(){
        validToken({roleId:6}).then(()=>{})
    }
})
</script>
<style scoped>
    #app_div{
        width: 100%;
        height: 100%;
        padding: 5px;
    }

.join_in_div{
    border-radius: 16px;
    padding: 16px;   
    width: 80%;
    font-size: 16px;
    height: 40%;
    position: fixed;
    z-index: 2;
    background: white;
    left: 10%;
    right: 10%;
    top:25%;
}
.join_msg_title{
    text-align: center;
    font-size: 18px;
}
.join_msg_body{
     text-indent:2em;
     margin-top:20px;
}
.join_pay{
    font-size: 18px;
    font-weight: bold;
}
</style>