<template>
    <div id="app">
        <div id="top_btn_div" style="position:fixed;top:0px;right:0px; height:46px;line-height:46px; z-index:2;width:60px;">
            <a href="javascript:void(0)" @click="showSearchDialog=true;">搜索</a>
        </div>
        <div id="serch_div">
            <van-dialog v-model="showSearchDialog" title="搜索" show-cancel-button @confirm="onConfirm">
                <!-- <button @click="date_show=true"/> -->
                <van-cell-group>
                    <van-field v-model="serarchModel.name" label="关键字" placeholder="请输入车主姓名或车主手机号码" />
                    <van-field v-model="serarchModel.licensePlate" label="车牌号" placeholder="请输入车牌号" />
                    <van-field  readonly
                                clickable
                                name="picker"
                                :value="serarchModel.orderStatusText"
                                label="状态"
                                placeholder="点击选择状态"
                                @click="action_show = true"
                                />
                </van-cell-group>
            </van-dialog>
            <van-action-sheet
                v-model="action_show"
                :actions="actions"
                cancel-text="取消"
                close-on-click-action
                @cancel="onCancel"
                @select="onSelect"/>
            <van-action-sheet v-model="date_show" title="选择日期">
                <div class="content">
                    <van-datetime-picker
                    v-model="visitBody.visitTime"
                    @confirm="setVisitTime"
                    @cancel="date_show=false"
                    type="datehour"
                    title="选择预约时间"
                    :min-date="minDate"
                    :max-date="maxDate"/>
                </div>
            </van-action-sheet>         
        </div>
        <div class="cards_div" >
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
            <van-card v-for="(item,index) in tableData" :key="index"
                :title="item.licensePlate"
                :thumb="$cosImg(item.frontLicensePhoto)">
                <template #tags>
                    <van-tag plain :color="tagStyle(item.transactionStatus)" type="danger">{{item.isWeighing&&item.transactionStatus==1?'正在过磅':$enum.transactionStatusEnum[item.transactionStatus]}}</van-tag>
                </template>
                <template #desc>
                    <div>
                        <!-- {{item.model}} -->
                       预约上门时间：{{item.visitTime}}
                    </div>
                    <div>
                        {{item.provinceName+"  "+item.cityName + "  "+item.countryName}}
                    </div>
                </template>
                <template #footer>
                    <van-button size="small" v-if="item.isWeighing?(!item.autoGroundPhoto&&!item.autoTrailerPhoto):(item.transactionStatus>=1&&!item.autoGroundPhoto&&!item.autoTrailerPhoto)"  @click="toPhoto(item)">拖车照片上传</van-button>
                    <van-button size="small" v-if="item.transactionStatus==0&&!item.visitTime" @click="visitBody.id=item.orderId;date_show= true">预约上门</van-button>
                    <van-button size="small" v-if="item.transactionStatus==0&&item.visitTime" @click="$router.push({path:'/pricingQuestion',
                    query:{mobile:item.mobile,orderId:item.orderId,autoWeight:item.autoWeight,customerPrice:item.customerPrice,scrapType:item.scrapType,agentId:item.agentId,city:item.city,province:item.province,country:item.country,id:item.id,license:item.licensePlate}})" >报废处理</van-button>
                    <!-- <van-button size="small" @click="$router.push({path:'/morderdetail',query:{id:item.orderId,go_url:'pricingIndex',go_active:0}})">问题反馈</van-button> -->
                    <van-button size="small" type="info"  @click="$router.push({path:'/morderdetail',query:{id:item.orderId,go_url:'pricingIndex',go_active:0}})">详情</van-button>
                </template>
            </van-card>
                <div style="margin-bottom:80px;line-height:40px;text-align:center;color:#c1c1c1">
                    没有更多了
                </div>
            </van-pull-refresh>
        </div>
    </div>
</template>
<script>
import {pricingActive,pricingUser} from '../../store/pricing'
import {mpMakedList,setVisitTime} from '../../api/pricing'
import { CellGroup,Field,ActionSheet,DatetimePicker,PullRefresh,Card,Tag,Button, Dialog  } from 'vant';
    export default({
        name:'pricing',
        components:{
            [Field.name]: Field,
            [CellGroup.name]: CellGroup,
            [ActionSheet.name]: ActionSheet,
            [DatetimePicker.name]: DatetimePicker,
            [PullRefresh.name]: PullRefresh,
            [Card.name]: Card,
            [Tag.name]: Tag,
            [Button.name]: Button,
            [Dialog.Component.name]:Dialog.Component
        },
        data() {
            return {
                agentUser:'',
                actions: [],
                isLoading:false,
                date_show:false,
                action_show:false,
                showSearchDialog:false,
                columns:[],
                serarchModel:{
                    name:'',
                    licensePlate:'',
                    start_date:'',
                    end_date:'',
                    orderStatus:'',
                    orderStatusText:'',
                },
                pageindex:1,
                totalPages:1,
                tableData:[],
                visitBody:{
                    id:'',
                    visitTime:new Date(),
                },
                minDate: new Date(this.$util.isIOS()?'2020/01/01':'2020-01-01'),
                maxDate: new Date(this.$util.isIOS()?'2066/12/22':'2066-12-22')
            }
        },
        methods:{
            toPhoto(item){
                if(this.$util.isIOS()){
                    window.location.href = window.origin+"/pricingPhotos?orderId="+item.orderId+"&go_url=pricingIndex&go_active=0";
                }else{
                    this.$router.push({path:'/pricingPhotos',query:{orderId:item.orderId,go_url:'pricingIndex',go_active:0}})
                }
            },
            parseDateFull(time){
                if(!time){
                    return '';
                }
                var datetime = new Date(time);
                if(datetime.getFullYear().toString()<2002){
                    return '';
                }
                var year = datetime.getFullYear().toString();
                var month = datetime.getMonth().toString() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
                var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
                var hour = datetime.getHours()< 10 ? "0" + datetime.getHours() : datetime.getHours();
                var minute = datetime.getMinutes()< 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
                var second = datetime.getSeconds()< 10 ? "0" + datetime.getSeconds() : datetime.getSeconds();
                return year + "-" + month + "-" + date+" "+hour+":"+minute+":"+second;
            },
            setVisitTime(){
                this.date_show= false;
                this.visitBody.visitTime =this.parseDateFull(this.visitBody.visitTime);
                console.log(this.visitBody);
                this.$toast.loading({duration: 0,forbidClick: true,message: '提交中',});
                setVisitTime(this.visitBody).then(res=>{
                    this.$toast.clear();
                    if(res.status){
                        this.onRefresh();
                    }
                    console.log(res);
                });
            },
            tagStyle(status){
                switch(status){
                    case 2:
                        return '#07c160';
                    case 1:
                        return '#efa74f';
                    default:
                        return '#1989fa';
                }
            },
            initTableData(that){
                that = that||this;
                var body = {
                    IsDesc:false,
                    PageSize:10,
                    PageIndex:that.pageindex,
                    AgentId:that.agentUser.agentId,
                    PriceMakerId:that.agentUser.priceMakerId,
                    InStatus:'50,60,70,80,90',
                    LikeLicense:that.serarchModel.licensePlate,
                    SearchContent:that.serarchModel.name,
                    TransactionStatus:that.serarchModel.orderStatus,
                    SortField:'transactionStatus'
                }
                this.$toast.loading({duration: 0,forbidClick: true,message: '加载中',});
                mpMakedList(body).then(res=>{
                    this.$toast.clear();
                    var data = res&&(res.content||[]);
                    data.forEach(e => {
                        that.tableData.push(e);
                    });
                    this.isLoading = false
                    that.pageindex++;
                    that.totalPages = res.totalPages;
                });
            },
            getDocumentTop() {
                var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
                if (document.body) {
                    bodyScrollTop = document.body.scrollTop;
                }
                if (document.documentElement) {
                    documentScrollTop = document.documentElement.scrollTop;
                }
                scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;    return scrollTop;
            },
            getWindowHeight() {
                var windowHeight = 0;
                if (document.compatMode == "CSS1Compat") {
                    windowHeight = document.documentElement.clientHeight;
                } else {
                    windowHeight = document.body.clientHeight;
                }
                return windowHeight;
            },
            getScrollHeight() {
                var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
                if (document.body) {
                    bodyScrollHeight = document.body.scrollHeight;
                }
                if (document.documentElement) {
                    documentScrollHeight = document.documentElement.scrollHeight;
                }
                scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;    return scrollHeight;
            },
            onCancel(){
            },
            onConfirm(){
                console.log(this.serarchModel);
                this.pageindex = 1;
                this.tableData = [];
                this.isLoading= false;
                this.initTableData(this);
            },
            onSelect(item){
                console.log(item);
                this.serarchModel.orderStatusText = item.name;
                this.serarchModel.orderStatus = item.id;
            },
            onRefresh(){
                this.pageindex = 1;
                this.tableData = [];
                this.initTableData(this);
            },
            scrollEvent(){
                var active = pricingActive.get();
                if(active == 0&&(this.pageindex<this.totalPages) && !this.isLoading&&(this.getScrollHeight()-1) <= this.getWindowHeight() + this.getDocumentTop()){
                    this.initTableData(this);
                }
            }
        },
        mounted(){
            this.agentUser = pricingUser.get();
            this.initTableData(this);
            this.actions.push({name:'不选择',id:''});
            for(var i in this.$enum.transactionStatusEnum){
                this.actions.push({name:this.$enum.transactionStatusEnum[i],id:i});
            }
            console.log(this.actions);
            window.addEventListener('scroll',this.scrollEvent);
        },
        destroyed(){
            window.removeEventListener('scroll',this.scrollEvent);
        }
    })
</script>
<style scoped>
.top_btn_div{
    position: fixed;top: 0px;right: 0px;
}
</style>
