<template>
    <div id="user_info_div">
        <div style="margin:20px 0px;">            
        </div>
        <div>
            <div class="user_info">
                <van-cell-group >
                    <van-cell title="账号：" :value="user.loginName" />
                </van-cell-group>
                <van-cell-group style="padding:10px 0px;">
                    <van-cell title="姓名：" :value="user.realName" />
                </van-cell-group>
                <!-- <van-cell-group style="padding:10px 0px;">
                    <div class="cell_left">付款权限：</div><div class="cell_right"> {{user.authPay==0?'需要审核':'无需审核'}}</div>
                </van-cell-group> -->
            </div>           
        </div>
        <van-button type="default" @click="user_layout" plain   block>安全退出</van-button>
    </div>
</template>
<script>
import {commission,saveDoorPhoto} from '../../api/pricing'
import {userLogout} from '../../api/login'
import {logout} from '../../store/token'
import {pricingUser} from '../../store/pricing'
import { Empty,Uploader,Button,CellGroup,Cell,ActionSheet,Dialog } from 'vant';
export default({
    name:'detail',
    components:{
        [Empty.name]:Empty,
        [Uploader.name]:Uploader,
        [Button.name]:Button,
        [CellGroup.name]:CellGroup,
        [Cell.name]:Cell,
        [ActionSheet.name]:ActionSheet,
        [Dialog.Component.name]:Dialog.Component
    },
    data() {
        return {
            agentAction:{
                show:false
                ,
                actions:[]
            },
            cummission_show:false,
            img1_show:false,
            img2_show:false,
            id:this.$route.query.id,        
            user:'',  
            formData:{
                id: 0,
                maxCommission: '',
                minCommission: '',
                percentCommission: ''
            }
        }
    },
    methods:{
        switchAgent(){

        },
        validator(val) {
             return val>=200;
        },
        percentVal(val){
            return val<=100&&val>0;
        },
        maxValidator(val){
            console.log(val);
            return parseFloat(this.formData.maxCommission)>parseFloat(this.formData.minCommission);
        },
        beforeClose(){
            this.formData.id = this.user.id;
            commission(this.formData).then(res=>{
                this.$toast({
                    type:res.status?'success':'fail',
                    message:res.message
                })
                console.log(res);
                this.cummission_show=false;
            });
        },
        user_layout(){
            userLogout().then(res=>{
                this.$toast({type:res.status?'success':'fail',message:res.message});
            }).finally(()=>{
                logout();
            })
        },
        afterRead(file){
            console.log(file);
            var filename = file.file.name;
            var ext = filename.substr(filename.lastIndexOf('.'));
            this.$util.uploadFile(ext,file,(err,data)=>{
                var filename = data.Location.substr(data.Location.lastIndexOf('/')+1);
                var fileurl = this.$global.BaseImagePath + filename;
                console.log(fileurl);
                this.user.doorPhoto = fileurl;
                saveDoorPhoto({doorPhoto:fileurl,id:this.user.id}).then(()=>{
                    this.$toast('替换成功');
                });
            });
            return;   
        }
    },
    created(){
        this.user = pricingUser.get();
    }
})
</script>
<style scoped>
.cell_left{
    display:inline-block;width:50%;
}
.cell_right{
    display:inline-block;width:50%;text-align:right;
}
.form_img{
    width: 80px;
    height: 80px;
}
</style>