<template>
    <div id="newlist_app" style="height:80vh;">
        <div id="top_btn_div" style="position:fixed;top:0px;right:0px; height:46px;line-height:46px; z-index:2;width:60px;">
            <a href="javascript:void(0)" @click="showSearchDialog=true;">搜索</a>
        </div>
        <div id="serch_div">
            <van-dialog v-model="showSearchDialog" title="搜索" show-cancel-button @confirm="onConfirm">
                <van-cell-group>
                    <van-field v-model="serarchModel.name" label="关键字" placeholder="请输入车主姓名或车主手机号码" />
                    <van-field v-model="serarchModel.licensePlate" label="车牌号" placeholder="请输入车牌号" />
                </van-cell-group>
            </van-dialog>
            <van-action-sheet
                v-model="action_show"
                :actions="actions"
                cancel-text="取消"
                close-on-click-action
                @cancel="onCancel"
                @select="onSelect"/>
            <van-action-sheet v-model="date_show" title="选择日期">
                <div class="content">
                    <van-datetime-picker
                    type="date"
                    title="选择年月日"
                    />
                </div>
            </van-action-sheet>         
        </div>
        <div class="cards_div" style="height: 100vh;">    
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">         
            <van-card v-for="(item,index) in tableData" :key="index"
                :title="item.licensePlate"
                :thumb="$cosImg(item.frontLicensePhoto)">
                <template #tags>
                    <van-tag plain :color="tagStyle(item.orderStatus)" type="danger">{{$enum.myOrderStatus[item.orderStatus]}}</van-tag>
                </template>
                <template #desc>
                    <div>
                        {{item.model}}
                    </div>
                    <div>
                        {{item.provinceName+"  "+item.cityName + "  "+item.countryName}}
                    </div>
                </template>
                <template #footer>
                    <van-button size="small" @click="setPricing(item.orderId)" >获取此线索</van-button>
                    <van-button size="small" type="info"  @click="$router.push({path:'/morderdetail',query:{id:item.orderId,go_url:'pricingIndex',go_active:1}})">详情</van-button>
                </template>
                </van-card>
            <div style="margin-bottom:80px;line-height:100px;text-align:center;color:#c1c1c1">
                没有更多了
            </div>
        </van-pull-refresh>
        </div>
    </div>
</template>
<script>
import {priceMakerPage,setPriceMaker} from '../../api/pricing'
import {pricingActive,pricingUser} from '../../store/pricing'
import { CellGroup,Field,ActionSheet,DatetimePicker,PullRefresh,Card,Tag,Button, Dialog } from 'vant';
    export default({
        name:'pricing',
        components:{
            [Field.name]: Field,
            [CellGroup.name]: CellGroup,
            [ActionSheet.name]: ActionSheet,
            [DatetimePicker.name]: DatetimePicker,
            [PullRefresh.name]: PullRefresh,
            [Card.name]: Card,
            [Tag.name]: Tag,
            [Button.name]: Button,
            [Dialog.Component.name]:Dialog.Component
        },
        data() {
            return {
                agentUser:'',
                actions: [],
                isLoading:false,
                date_show:false,
                action_show:false,
                showSearchDialog:false,
                columns:[],
                serarchModel:{
                    name:'',
                    licensePlate:'',
                    start_date:'',
                    end_date:'',
                    orderStatus:'',
                    orderStatusText:'',
                },
                pageindex:1,
                totalPages:1,
                tableData:[],
            }
        },
        methods:{
            tagStyle(status){
                switch(status){
                    case 8:
                        return '#07c160';
                    case 7:
                        return '#c1c1c1';
                    default:
                        return '#1989fa';
                }
            },
            initTableData(that){
                if(that.pageindex>that.totalPages){
                    that.isLoading = false;
                    return;
                }
                var body = {
                    IsDesc:true,
                    PageSize:10,
                    PageIndex:that.pageindex,
                    AgentId:that.agentUser.agentId,
                    OrderStatus:40,
                    LikeLicense:that.serarchModel.licensePlate,
                    SearchContent:that.serarchModel.name,
                    TransactionStatus:that.serarchModel.orderStatus
                }
                that.$toast.loading({duration: 0,forbidClick: true,message: '加载中',});
                that.isLoading = true;
                priceMakerPage(body).then(res=>{
                    that.$toast.clear();
                    var data = res&&(res.content||[]);
                    data.forEach(e => {
                        that.tableData.push(e);
                    });
                    that.pageindex++;
                    that.totalPages = res.totalPages;
                    that.isLoading = false;
                });
            },
            getDocumentTop() {
                var scrollTop = 0, bodyScrollTop = 0, documentScrollTop = 0;
                if (document.body) {
                    bodyScrollTop = document.body.scrollTop;
                }
                if (document.documentElement) {
                    documentScrollTop = document.documentElement.scrollTop;
                }
                scrollTop = (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;    return scrollTop;
            },
            getWindowHeight() {
                var windowHeight = 0;
                if (document.compatMode == "CSS1Compat") {
                    windowHeight = document.documentElement.clientHeight;
                } else {
                    windowHeight = document.body.clientHeight;
                }
                return windowHeight;
            },
            getScrollHeight() {
                var scrollHeight = 0, bodyScrollHeight = 0, documentScrollHeight = 0;
                if (document.body) {
                    bodyScrollHeight = document.body.scrollHeight;
                }
                if (document.documentElement) {
                    documentScrollHeight = document.documentElement.scrollHeight;
                }
                scrollHeight = (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;    return scrollHeight;
            },
            setPricing(orderid){
                this.$dialog.confirm({
                    title: '提示',
                    message: '确定获取此线索？',
                }).then(() => {
                    var body = {
                        orderId:orderid,
                        makerId:this.agentUser.priceMakerId,
                    };
                    this.$toast.loading({duration: 0,forbidClick: true,message: '获取中',});
                    setPriceMaker(body).then(res=>{
                        this.$toast.clear();
                        this.pageindex = this.pageindex - 1;
                        this.onConfirm();
                        console.log(res);
                    });
                }).catch(()=>{
                    console.log('cancel');
                });
            },
            onCancel(){
            },
            onConfirm(){
                console.log(this.serarchModel);
                this.pageindex = 1;
                this.tableData = [];
                this.initTableData(this);
            },
            onSelect(item){
                console.log(item);
                this.serarchModel.orderStatusText = item.name;
                this.serarchModel.orderStatus = item.id;
            },
            onRefresh(){
                this.pageindex = 1;
                this.tableData = [];
                this.initTableData(this);
            },
            scrollEvent(){
              var active = pricingActive.get();
              if(active == 1 && !this.isLoading&&(this.getScrollHeight()-1) <= this.getWindowHeight() + this.getDocumentTop()){
                  this.initTableData(this);
              }
            }
        },
        destroyed(){
            window.removeEventListener('scroll',this.scrollEvent);
        },
        mounted(){
            this.agentUser = pricingUser.get();
            this.initTableData(this);
            window.addEventListener('scroll',this.scrollEvent);
        },
    })
</script>
<style scoped>
.top_btn_div{
    position: fixed;top: 0px;right: 0px;
}
</style>